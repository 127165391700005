import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
// modules
import useBlog from "../../modules/use_blog"
// components
import Layout from "../../components/layout"
import TitleLeft from "../../components/molecules/title_left"
import Posts from "../../components/molecules/posts"
import SEO from "../../components/seo"

const StyledBlog = styled.div`
  background: #e8ecef;
  display: grid;
  grid-column-end: -1;
  grid-column-start: 1;
  grid-row-gap: 10px;
  grid-template-columns: inherit;
  grid-template-rows: auto auto;
  padding: 15px 0;
`

const OuterWrapper = styled.div`
  background: #ffffff;
  grid-column-end: ${props => props.gridColumnEnd};
  grid-column-start: ${props => props.gridColumnStart};

  @media (min-width: 960px) {
    display: flex;
    justify-content: ${props => props.justifyContent};
  }
`

const InnerWrapper = styled.div`
  box-sizing: border-box;
  min-height: 200px;
  padding: 20px;
  width: 930px;

  @media (max-width: 959px) {
    margin-left: ${props => props.marginLeft};
    margin-right: ${props => props.marginRight};
    width: auto;
  }
`

const Blog = ({
  data,
  location
}) => {
  const newsPosts = data.allMicrocmsNews.edges.map(({ node }) => node)
  const blogPosts = useBlog()
  return (
    <Layout location={location}>
      <StyledBlog>
        <OuterWrapper
          gridColumnEnd="-2"
          gridColumnStart="1"
          justifyContent="flex-end"
        >
          <InnerWrapper marginLeft="15px">
            <TitleLeft
              level={2}
              fontSize="large"
              heading="お知らせ"
            />
            <Posts
              posts={newsPosts}
              limit={5}
              slug="news"
              category="お知らせ"
            />
          </InnerWrapper>
        </OuterWrapper>
        <OuterWrapper
          gridColumnEnd="-1"
          gridColumnStart="2"
        >
          <InnerWrapper marginRight="15px">
            <TitleLeft
              level={2}
              fontSize="large"
              heading="施工事例"
            />
            <Posts
              posts={blogPosts}
              limit={5}
              slug="case"
              category="施工事例"
            />
          </InnerWrapper>
        </OuterWrapper>
      </StyledBlog>
    </Layout>
  )
}

export default Blog

export const query = graphql`
  query {
    allMicrocmsNews(sort: { fields: date, order: DESC }) {
      edges {
        node {
          id: newsId
          title
          date
          formattedDate: date(formatString: "YYYY年MM月DD日")
        }
      }
    }
  }
`

export const Head = ({ location }) => (
  <SEO
    title="ブログ"
    description="株式会社マルトモのブログページです。"
    pathname={location.pathname}
  />
)
